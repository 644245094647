import React, {useState} from 'react';
import LogIn  from '../components/LogIn';
import LogInQuery from '../../queries/LogInQuery';
import {graphql} from 'gatsby';
import '../scss/settings.scss'; 
import useLS from '../hooks/useLS';


const Settings = ({data}) => {

  const [show, setShow] = useState(false);
  
  useLS('login'); 

  setTimeout(() => {
    setShow(true);
  }, 200);

  const storesData = data.wp.pageBy.stores.storeList.stores || [];
  const storesDataRefactored = storesData.map((itm) => {
    return {value: itm.storeId, label: itm.storeName}
  })
  const videoData = data.wp.pageBy.stores.screensaverList.screensaver || [];
  const videoDataRefactored = videoData.map((itm) => {
    return {value: itm.videoId, label: itm.videoName}
  })
    
  const setToLocalStorage = (key, value) => {
      localStorage.setItem(key, value);
  }

    return show && <LogIn videoData={videoDataRefactored} storesData={storesDataRefactored} overrideLS setToLocalStorage={setToLocalStorage} />
}

export default Settings;


export const query = graphql`
  {
    wp {
      pageBy(pageId: 522) {
        id
          stores {
          screensaverList {
            screensaver {
              videoId
              videoName
            }
          }
          storeList {
            stores {
              storeId
              storeName
            }
          }
        }
      }
    }
  }
`;


