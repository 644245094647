import { navigate } from "gatsby";

const useLS = (page) => {

    const pw = typeof window !== 'undefined' ? localStorage.getItem('SKYpassword') : undefined;
    const sID = typeof window !== 'undefined' ? localStorage.getItem('SKYStoreID') : undefined;
    const video = typeof window !== 'undefined' ? localStorage.getItem('SKYvideoId') : undefined;

    if (!pw || !sID) {
        typeof window !== 'undefined' && window.location.pathname !== '/' && navigate("/");
    }

    if (page === 'login' && pw && sID) {
        typeof window !== 'undefined' && navigate("/home");
    }

    return {video : video, login: true, storeID: sID};
}


export default useLS;